import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => {
  const imageData = useStaticQuery(graphql`
      query {
          peacockLogo: file(relativePath: { eq: "peacock_1f99a.png"}) {
              childImageSharp {
                  fluid(maxWidth: 200) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
      }
  `)

  return (
    <header className="bg-white border-b-2 border-gray-500">
      <div className="my-0 mx-auto px-4 py-3 flex items-center max-w-screen-xl">
        <div className="h-20 w-20 mr-4">
          <Img fluid={imageData.peacockLogo.childImageSharp.fluid} />
        </div>
        <h1>
          <Link className="text-4xl font-bold text-peacock-blue" to="/">{siteTitle}</Link>
        </h1>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
